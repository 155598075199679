import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import theme from '../../utils/themeconstants';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledButton = styled.div`
  font-size: 22px;
  font-weight: bold;
  display: flex;
  justify-content:${props => (props.justify ? props.justify : 'center')};
  align-content:center;
  cursor: pointer;

  p {
    height: 50px;
    color: ${props => (props.color === 'white' || props.color === '#FFFFFF' ? theme.colors.primary : 'white')};
    background-color: ${props => props.color};
    border-radius: 28px;
    align-self: center;
    vertical-align: middle;
    margin: 0;
    text-align: center;
    padding: 8px 1.5em;
    text-decoration: none;
    transition: box-shadow 0.2s linear;
    &:hover {
      box-shadow: 0 10px 32px rgba(0,0,0,0.1);
    }
  }
`;

const Button = ({
  children,
  color,
  to,
  textColor,
  justify,
}) => (
  <StyledLink to={to}>
    <StyledButton color={color} justify={justify}>
      <p style={{ color: textColor }}>
        {children}
      </p>
    </StyledButton>
  </StyledLink>
);

export default Button;
