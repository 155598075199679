import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'react-grid-system';

import theme from '../../utils/themeconstants';
import TechJson from './tech_json';

const StyledCol = styled(Col)`
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    margin-bottom: 2rem;
  }
`;

const Email = styled.div`
    background-color: ${theme.colors.bgPrimary};
    border-radius: ${theme.radius.md}px;
    padding: 16px;
    box-shadow: ${theme.shadows.default};
`;

const EmailIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 16px;
  right: 32px;
  opacity: 0.2;
`;

const EmailTitle = styled.h4`
  margin: 0;
`;

const EmailTo = styled.p`
  font-size: 9px;
  margin-bottom: 16px;
  border-bottom: 1px solid #ECEFF2;
`;

const FakeText = styled.div`
  background-color: #ECEFF2; 
  border-radius: 8px;
  height: 14px;
  min-width: 14px;
  width: ${props => props.width};
  display: inline-block;
  margin-right: 8px;
`;

const FakeParagraph = styled.div`
  margin-bottom: 8px;
`;

const FakeLine = styled.div`
  margin: 0; 
  padding: 0;
  height: 20px;
`;

const DetectedText = styled(FakeText)`
  background-color: ${props => (props.isdetected ? props.background : '#ECEFF2')};
  padding: 0 8px;
  top: -4px;
  position: relative;
  transition: background-color 0.4s;
  p {
    opacity: ${props => (props.isdetected ? 1 : 0)};
    color: white;
    line-height: 14px;
    margin: 0;
    font-size: 9px;
    font-weight: 900;
    transition: opacity 0.4s;
  }
`;

const TechEmail = () => {
  const [detected, setDetected] = useState([]);

  useEffect(() => {
    function animate() {
      setTimeout(() => { setDetected(prevState => prevState.concat('flight')); }, 1000);
      setTimeout(() => { setDetected(prevState => prevState.concat('outbound')); }, 2000);
      setTimeout(() => { setDetected(prevState => prevState.concat('from')); }, 3000);
      setTimeout(() => { setDetected(prevState => prevState.concat('to')); }, 4000);
      setTimeout(() => { setDetected(prevState => prevState.concat('class')); }, 5000);
      setTimeout(() => { setDetected(prevState => prevState.concat('emissions')); }, 5000);
      setTimeout(() => { setDetected([]); }, 6000);
    }
    animate();
    const animationinterval = () => {
      setInterval(() => {
        animate();
      }, 6000);
    };
    animationinterval();
    return function cleanup() {
      clearInterval(animationinterval);
    };
  }, []);
  return (
    <Row>
      <StyledCol md={8}>
        <Email>
          <EmailIcon color="black" icon={faEnvelope} />
          <EmailTitle>Booking confirmation</EmailTitle>
          <EmailTo>to: you@me.com</EmailTo>
          <FakeParagraph>
            <FakeLine>
              <FakeText width="10%" />
              <FakeText width="18%" />
              <FakeText width="8%" />
            </FakeLine>
            <FakeLine>
              <FakeText width="7%" />
              <FakeText width="12%" />
              <FakeText width="15%" />
              <DetectedText
                background="#F20050"
                isdetected={detected.indexOf('flight') > -1}
              >
                <p>Flight</p>
              </DetectedText>
              <FakeText width="19%" />
              <FakeText width="5%" />
            </FakeLine>
          </FakeParagraph>
          <FakeParagraph>
            <FakeLine>
              <DetectedText
                background="#53DD6C"
                isdetected={detected.indexOf('outbound') > -1}
              >
                <p>Outbound</p>
              </DetectedText>
            </FakeLine>
            <FakeLine>
              <FakeText width="11%" />
              <DetectedText
                background="#F9D332"
                detected={false}
                isdetected={detected.indexOf('from') > -1}
              >
                <p>From: CPH</p>
              </DetectedText>
              <FakeText width="5%" />
              <FakeText width="13%" />
              <DetectedText
                background="#F9D332"
                detected={false}
                isdetected={detected.indexOf('to') > -1}
              >
                <p>To: ZRH</p>
              </DetectedText>
            </FakeLine>
            <FakeLine>
              <FakeText width="15%" />
              <FakeText width="7%" />
              <FakeText width="8%" />
              <FakeText width="12%" />
              <DetectedText
                background="#3E92CC"
                detected={false}
                isdetected={detected.indexOf('class') > -1}
              >
                <p>Economy</p>
              </DetectedText>
            </FakeLine>
          </FakeParagraph>
        </Email>
      </StyledCol>
      <StyledCol md={4}>
        <TechJson
          detected={detected}
        />
      </StyledCol>
    </Row>
  );
};

export default TechEmail;
