import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import GithubPng from '../../images/github.png';

const Github = styled.div`
  background-color: transparent;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  min-height: 300px;
`;

const GithubImage = styled.img`
  position: absolute;
  left: ${props => props.x}px;
  top: ${props => props.y}px;
  height: 70px;
  margin: 0;
`;

const User = styled.div`
  position: absolute;
  left: ${props => props.x}px;
  top: ${props => props.y}px;
  transform: rotateZ(45deg) scale(1);
  box-shadow: 20px 20px 30px rgba(0,0,0,0.10);
  border-radius: ${props => props.size / 4}px;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  transition: transform 0.4s, box-shadow 0.4s, opacity 0.4s;
  opacity: ${props => props.opacity};
  overflow:hidden;
  &:before {
    content: "";
    position: absolute;
    top: -36%;
    left: -15%;
    z-index: -1;
    width: 150%;
    height: 150%;
    background: url(${props => props.image}) 0 0;
    background-size: cover;
    transform: rotate(-45deg);
  }
  &:hover{
    transform: rotateZ(45deg) scale(1.05);
    box-shadow: 25px 25px 30px rgba(0,0,0,0.05);
    opacity: 1;
  }
`;


const TechGithub = () => {
  const [containerSize, setcontainerSize] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const container = document.getElementById('githubgraph');
    function getSize() {
      return {
        x: container.clientWidth,
        y: container.clientWidth,
      };
    }
    container.addEventListener('resize', setcontainerSize(getSize()));
    return () => {
      container.removeEventListener('resize', setcontainerSize(getSize()));
    };
  }, []);

  const containerwidth = containerSize.x - 50;
  const containerheight = containerSize.y;
  const heightdivisor = containerwidth >= 400 ? 4 : 1;
  return (
    <StaticQuery
      query={graphql`
      {
        allContributorsJson {
          edges {
            node {
              login
              image
            }
          }
        }
      }
    `}
      render={data => (
        <Github id="githubgraph">
          <GithubImage
            src={GithubPng}
            x={Math.round((containerwidth / 2 - 35))}
            y={Math.round((containerheight / heightdivisor - 35))}
            alt="github"
          />
          {
            data.allContributorsJson.edges.slice(0, 6).map((edge, i) => {
              const size = 45;
              const radius = 60;
              const step = (2 * Math.PI) / 6;
              const angle = 0 + i * step;
              return (
                <User
                  x={Math.round((containerwidth / 2 - size / 2) + radius * 2 * Math.cos(angle))}
                  y={
                    Math.round(
                      (containerheight / heightdivisor - size / 2) + radius * Math.sin(angle),
                    )
                  }
                  size={size}
                  key={edge.node.login}
                  image={edge.node.image}
                  opacity={0.7}
                />
              );
            })
          }
          {
            data.allContributorsJson.edges.slice(7, 20).map((edge, i) => {
              const size = 30;
              const radius = 100;
              const step = (2 * Math.PI) / 12;
              const angle = 0 + i * step + 11;
              return (
                <User
                  x={Math.round(containerwidth / 2 + radius * 2 * Math.cos(angle) - size / 2)}
                  y={
                    Math.round(
                      containerheight / heightdivisor + radius * Math.sin(angle) - size / 2,
                    )
                  }
                  size={size}
                  key={edge.node.login}
                  image={edge.node.image}
                  opacity={0.3}
                />
              );
            })
          }
          {
            data.allContributorsJson.edges.slice(21, 33).map((edge, i) => {
              const size = 28;
              const radius = 140;
              const step = (2 * Math.PI) / 12;
              const angle = 38.5 + i * step;
              return (
                <User
                  x={Math.round(containerwidth / 2 + radius * 2 * Math.cos(angle) - size / 2)}
                  y={
                    Math.round(
                      containerheight / heightdivisor + radius * Math.sin(angle) - size / 2,
                    )
                  }
                  size={size}
                  key={edge.node.login}
                  image={edge.node.image}
                  opacity={0.1}
                />
              );
            })
          }
        </Github>
      )
    }
    />
  );
};

export default TechGithub;
