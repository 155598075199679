import React from 'react';
import styled from 'styled-components';
import { Container as GirdContainer, Row, Col } from 'react-grid-system';

import theme from '../../utils/themeconstants';


const LeadTitle = styled.h1`
  font-size: 60px;
  text-align: center;
  margin-bottom: 60px;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    font-size: 48px;
  }
`;

const StyledLeadText = styled.p`
  margin: 0 auto;
`;

const LeadText = ({ title, children }) => (
  <GirdContainer>
    <Row>
      <Col>
        <LeadTitle>
          {title}
        </LeadTitle>
      </Col>
    </Row>
    <Row>
      <Col md={8} offset={{ md: 2 }}>
        <StyledLeadText>
          {children}
        </StyledLeadText>
      </Col>
    </Row>
  </GirdContainer>
);

export default LeadText;
