import React from 'react';
import styled from 'styled-components';

import theme from '../../utils/themeconstants';

const StyledSection = styled.div`
  padding: 0;
  margin: 0 0 ${props => (props.sub ? theme.space.lg * 2 : theme.space.lg * 4)}px 0 ;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    margin-bottom: 2rem;
  }
`;

const StyleIntro = styled.div`
  margin-bottom: ${theme.space.lg * 2}px;
`;

const SectionTitle = styled.h2`
  font-weight: normal;
  text-align: center;
  margin-bottom: 1.25rem;
`;

const SectionLead = styled.h3`
  font-weight: lighter;
  text-align: center;
`;

const Section = ({
  children, title, lead, sub, id,
}) => (
  <StyledSection id={id} sub={sub}>
    {
      (title || lead) && (
        <StyleIntro>
          <SectionTitle>
            {title}
          </SectionTitle>
          <SectionLead>
            {lead}
          </SectionLead>
        </StyleIntro>
      )
    }
    {children}
  </StyledSection>
);

export default Section;
