import React from 'react';
import styled from 'styled-components';
import { Container as GridContainer, Row, Col } from 'react-grid-system';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

import theme from '../../utils/themeconstants';
import Link from '../../components/elements/link';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ContentContainer from '../../components/elements/pageContentContainer';
import LeadText from '../../components/elements/leadtext';
import Section from '../../components/elements/section';
import RepoFile from '../../images/svg/tech_deployment_repo.svg';
import CircleCiFile from '../../images/svg/tech_deployment_circleci.svg';
import CloundFunctionFile from '../../images/svg/tech_deployment_cloudfunction.svg';
import Button from '../../components/elements/button';

import TechEmail from '../../components/pagecomponents/tech_email';
import TechGithub from '../../components/pagecomponents/tech_github';
import Stage from '../../components/pagecomponents/tech_stage';


const PageContent = styled.div`
  position: relative;
`;

const Shard = styled.div`
  position: absolute;
  top: ${props => (props.top && props.top)};
  bottom: ${props => (props.bottom && props.bottom)};
  left: ${props => (props.left && props.left)};
  right: ${props => (props.right && props.right)};
  border-radius: ${props => props.size / 5}px;
  box-shadow: -10px 10px 20px rgba(0,0,0,0.2);
  background-color: white;
  transform: ${props => `rotate3d(${props.rotate}, -60deg)`};
  background-image: linear-gradient(to top right, #FD712D, #FFBE1D);
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  z-index: -1;
`;

const StyledCol = styled(Col)`
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    margin-bottom: ${props => (props.firstchild ? '2rem' : '4rem')};
  }
`;

const TextCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    margin-bottom: ${props => (props.firstchild ? '2rem' : '4rem')};
  }
`;

const Image = styled(Img)`
  perspective: 100px;
  transform: rotate3d(1,0,0, 55deg);
  border-radius: ${theme.radius.md}px;
  box-shadow: -50px 50px 100px rgba(0,0,0,0.05);
`;

const Devtools = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    padding: 0;
    margin-bottom: 2rem;
  }
`;

const DevToolBox = styled.div`
  width: 25%;
  padding-top: 25%;
  height: auto;
  border-radius: 10%;
  box-shadow: 10px 10px 40px rgba(0,0,0,0.1);
  background-image: linear-gradient(#ffffff, ${theme.colors.bgSecondary});
  transform: rotateZ(45deg);
  .contentsvg {
    position: absolute;
    transform: rotate(-45deg);
  }
`;

const Repo = styled(RepoFile)`
  top: 5%;
  left: 20%;
  width: 80%;
  height: 80%;
`;

const CircleCi = styled(CircleCiFile)`
  top: 15%;
  left: 15%;
  width: 70%;
  height: 70%;
`;

const CloundFunction = styled(CloundFunctionFile)`
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
`;

const TechSection = styled(Section)`
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    margin-bottom: 2rem !important;
  }
`;

const TechPage = () => (
  <Layout parent="company" dark>
    <SEO
      title="Tomorrow - Technology for climate impact"
      keywords={['tomorrow', 'carbon impact', 'company', 'technology', 'tech']}
    />
    <Stage />
    <PageContent>
      <ContentContainer>
        <Section>
          <LeadText title="data as a catalyst">
            Climate impact is about information,
             and modern technologies are the tools required to
             turn complex data into intuitive and actionable insights.
            We see data as the catalyst to large-scale behavioral change.
          </LeadText>
        </Section>
        <Section>
          <TechSection>
            <GridContainer>
              <Row>
                <StyledCol firstchild="true" md={8}>
                  <TechEmail />
                </StyledCol>
                <TextCol md={4}>
                  <Shard
                    size={50}
                    top="-30px"
                    right="10px"
                    rotate="1,1,0"
                  />
                  <Shard
                    size={20}
                    top="20px"
                    right="-4px"
                    rotate="0,0,1"
                  />
                  <h2>intelligent</h2>
                  <p>
                    Artificial Intelligence enables us to automatically
                     extract insights about your past activities, and their
                     associated carbon emissions.
                  </p>
                </TextCol>
              </Row>
            </GridContainer>
          </TechSection>
          <Section>
            <GridContainer>
              <Row>
                <StyledCol firstchild="true" md={7} push={{ md: 5 }} style={{ perspective: '1000px' }}>
                  <StaticQuery
                    query={graphql`
                      query {
                        placeholderImage: file(relativePath: { eq: "electricitymap.jpg" }) {
                          childImageSharp {
                            fluid(maxWidth: 600) {
                              ...GatsbyImageSharpFluid_noBase64
                            }
                          }
                        }
                      }
                    `}
                    render={data => <Image fluid={data.placeholderImage.childImageSharp.fluid} />}
                  />
                </StyledCol>
                <TextCol md={5} pull={{ md: 7 }}>
                  <Shard
                    size={60}
                    top="-14px"
                    right="-14px"
                    rotate="1,0,0.5"
                  />
                  <Shard
                    size={20}
                    top="60px"
                    right="0px"
                    rotate="1,0.5,0"
                  />
                  <h2>intuitive</h2>
                  <p>
                    We live in a world flooded with information.
                    By placing humans at the center, we strive to
                     craft user interfaces that are as intuitive and
                     educational as possible.
                  </p>
                  <p>
                    Explore{' '} 
                    <Link outward href="https://www.electricitymap.org" target="_blank">electricityMap</Link>
                    {' '}as an example.
                  </p>
                </TextCol>
              </Row>
            </GridContainer>
          </Section>
          <Section>
            <GridContainer>
              <Row>
                <StyledCol md={7}>
                  <TechGithub />
                </StyledCol>
                <TextCol md={5}>
                  <Shard
                    size={34}
                    top="-4px"
                    right="54px"
                    rotate="1,0.5,0.5"
                  />
                  <Shard
                    size={20}
                    top="30px"
                    right="-0px"
                    rotate="0,1,1"
                  />
                  <h2>open source</h2>
                  <p>
                    Communities get further than individuals.
                    Open Source is a unique way to foster consensus
                     and co-develop software that empowers everyone.
                  </p>
                  <p>
                    Become a contributor on{' '}
                    <Link outward href="https://github.com/tmrowco" target="_blank">
                      <FontAwesomeIcon size="lg" color="black" icon={faGithub} />
                      {' '}github
                    </Link>
                  </p>
                </TextCol>
              </Row>
            </GridContainer>
          </Section>
          <Section>
            <GridContainer>
              <Row>
                <StyledCol md={7} push={{ md: 5 }}>
                  <Devtools>
                    <DevToolBox>
                      <Repo className="contentsvg" />
                    </DevToolBox>
                    <DevToolBox>
                      <CircleCi className="contentsvg" />
                    </DevToolBox>
                    <DevToolBox>
                      <CloundFunction className="contentsvg" />
                    </DevToolBox>
                  </Devtools>
                </StyledCol>
                <StyledCol md={5} pull={{ md: 7 }}>
                  <Shard
                    size={60}
                    top="-10px"
                    right="100px"
                    rotate="1,1,0"
                  />
                  <Shard
                    size={20}
                    top="-14px"
                    right="164px"
                    rotate="1,1,0"
                  />
                  <h2>dev tools</h2>
                  <p>
                    Our ability to prototype quickly and iterate fast is critical.
                    From monorepos to serverless functions, we invest
                     heavily in our infrastructure to make our jobs easier.
                  </p>
                </StyledCol>
              </Row>
            </GridContainer>
          </Section>
          <GridContainer>
            <Row>
              <StyledCol md={12}>
                <h2 style={{ textAlign: 'center' }}>Interested in making a difference?</h2>
                <Button to="/jobs" color={theme.colors.red}>
                  Join us!
                </Button>
              </StyledCol>
            </Row>
          </GridContainer>
        </Section>
      </ContentContainer>
    </PageContent>
  </Layout>
);


export default TechPage;
