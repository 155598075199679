import React from 'react';
import styled/* , {  keyframes } */ from 'styled-components';
import { Parallax, Background } from 'react-parallax';

import theme from '../../utils/themeconstants';
import StageContainer from '../elements/stageContentContainer';

const Gradient = styled.div`
  height: 100vh;
  width: 100%;
  position:relative;
  top: 0;
`;

const StageTitle = styled.div`
  height: 100vh;
  display:flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: ${theme.space.lg}px;
  transform: translateZ(0);
  h1,h2 {
    color: black;
  }
`;

const Shapes = styled.div`
    height: 100vh;
    width: 100%;
    position: absolute;
    perspective: 1000px;
    /* mix-blend-mode: color-burn; */
    .para {
    overflow: visible !important;
    height: 33vh;
    /* &:nth-child(2){
      top: -100vh;
    }
    &:nth-child(3){
      top: -200vh;
    } */
  }
`;

const Shape = styled.div`
    position: absolute;
    border-radius: 15px;
    box-shadow: 0 0 120px rgba(0,0,0,0.2);
    margin: 0 20px;
    background-color: white;
    transform: translateZ(0);
    background-image: linear-gradient(to top right, #FD712D, #FFBE1D);
    opacity: 0.9;
  &.shape1{
    width: 35vw;
    height: 35vw;
    top: 10vh;
    left: 0;
    transform: rotate3d(0,1,1, -60deg);
    @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
      top: 15vh;
      width: 40vw;
      height: 40vw;
    }
  }
  &.shape2{
    width: 25vw;
    height: 25vw;
    top: 10vh;
    left: 41vw;
    transform: rotate3d(1,0,1, 290deg);
    @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
      top: 30vh;
      width: 30vw;
      height: 30vw;
    }
  }
  &.shape3{
    width: 35vw;
    height: 35vw;
    top: -25vh;
    right: -90vw;
    transform: rotate3d(1,1,1, 200deg);
    /* opacity: 0.5; */
    @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
      height: 55vw;
      width: 55vw;
      top: 15vh;
    }
  }
`;

const StageTech = () => (
  <Gradient>
    <Shapes>
      <Parallax strength={200} className="para" bgStyle={{ left: 0 }}>
        <Background>
          <Shape alt="tech1" className="shape shape1" />
        </Background>
      </Parallax>
      <Parallax strength={300} className="para" bgStyle={{ left: 0 }}>
        <Background>
          <Shape alt="tech1" className="shape shape2" />
        </Background>
      </Parallax>
      <Parallax strength={150} className="para" bgStyle={{ left: 0 }}>
        <Background>
          <Shape alt="tech1" className="shape shape3" />
        </Background>
      </Parallax>
    </Shapes>
    <StageContainer>
      <StageTitle>
        <h1>Technology for large-scale impact</h1>
      </StageTitle>
    </StageContainer>
  </Gradient>
);

export default StageTech;
