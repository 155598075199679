import React from 'react';
import styled from 'styled-components';

import theme from '../../utils/themeconstants';

const Json = styled.div`
  background-color: ${theme.colors.primary};
  border-radius: ${theme.radius.md}px;
  padding: 16px;
  box-shadow: ${theme.shadows.default};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: white;
    font-weight: 900;
    font-size: 12px;
  }
`;

const CodeLine = styled.p`
  margin: 0;
  padding-left: ${props => props.indent};
`;

const CodeItem = styled.span`
  opacity: ${props => (props.isdetected ? 1 : 0.2)};
  color: ${props => (props.isdetected ? props.color : 'white')};
  transition: opacity 0.4s;
`;

const TechJson = (props) => {
  const { detected = [] } = props;
  return (
    <Json>
      <div>
        <CodeLine>
          <CodeItem
            isdetected={detected.indexOf('flight') > -1}
            color="#F20050"
          >
            { detected.indexOf('flight') > -1 ? '"flight"' : '"···"'}
          </CodeItem>
          {': {'}
        </CodeLine>
        <CodeLine indent="20px">
          &quot;dir&quot;:{' '}
          <CodeItem
            isdetected={detected.indexOf('outbound') > -1}
            color="#53DD6C"
          >
            { detected.indexOf('outbound') > -1 ? '"outbound"' : '"···"'}
          </CodeItem>
          ,
        </CodeLine>
        <CodeLine indent="20px">
          &quot;from&quot;:{' '}
          <CodeItem
            isdetected={detected.indexOf('from') > -1}
            color="#F9D332"
          >
            { detected.indexOf('from') > -1 ? '"CPH"' : '"···"'}
          </CodeItem>
          ,
        </CodeLine>
        <CodeLine indent="20px">
          &quot;to&quot;:{' '}
          <CodeItem
            isdetected={detected.indexOf('to') > -1}
            color="#F9D332"
          >
            { detected.indexOf('to') > -1 ? '"ZRH"' : '"···"'}
          </CodeItem>
          ,
        </CodeLine>
        <CodeLine indent="20px">
          &quot;class&quot;:{' '}
          <CodeItem
            isdetected={detected.indexOf('class') > -1}
            color="#3E92CC"
          >
            { detected.indexOf('class') > -1 ? '"economy"' : '"···"'}
          </CodeItem>
          ,
        </CodeLine>
        <CodeLine indent="20px">
          &quot;emissions&quot;:{' '}
          <CodeItem
            isdetected={detected.indexOf('emissions') > -1}
            color="#FFFFFF"
          >
            { detected.indexOf('emissions') > -1 ? '"235kg"' : '"···"'}
          </CodeItem>
        </CodeLine>
        <CodeLine>{'}'}</CodeLine>
      </div>
    </Json>
  );
};

export default TechJson;
